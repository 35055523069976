<template>
  <div class="page pt-0 pb-0">
    <div class="first">
      <div class="bloc">
        <div class="columns">
          <div class="column is-1">
            <p class="arrow">
                <svg-icon
                  name="ic_arrow"
                  original
                  class="w-8 ml-48 pt-20 cursor-pointer"
                  @click="goToIndex"
                />
            </p>
          </div>
          <div class="column intro">
            <h1 class="title ">{{$t('texte296')}}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="bloc1 is-relative">
      <div class="second">
        <div class="columns">
          <div class="column">
<p class="objet_dev break-words text-black text-2xl"><em v-html="$t('texte297')"></em></p><br>
            <h1 v-html="$t('texte298')" class="objet text-black text-4xl font-bold"></h1>
            <p v-html="$t('texte299')" class="objet_dev break-words text-black text-xl"></p><br>
            <h1 v-html="$t('texte300')" class="objet text-black text-4xl font-bold"></h1>
            <p v-html="$t('texte301')" class="objet_dev break-words text-black text-xl"></p><br>
            <h1 v-html="$t('texte302')" class="objet text-black text-4xl font-bold ml-10"></h1>
            <p v-html="$t('texte303')" class="objet_dev break-words text-black text-xl ml-10"></p><br>

            <h1 v-html="$t('texte304')" class="objet text-black text-4xl font-bold ml-10"></h1>
            <p v-html="$t('texte305')" class="objet_dev break-words text-black text-xl ml-10"></p><br>

            <p v-html="$t('texte306')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
            <p v-html="$t('texte307')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
            <p v-html="$t('texte308')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
            <p v-html="$t('texte309')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
            <p v-html="$t('texte310')" class="objet_dev break-words text-black text-xl ml-10"></p><br>

            <h1 v-html="$t('texte311')" class="objet text-black text-4xl font-bold ml-10"></h1>
            <p v-html="$t('texte312')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
             <p v-html="$t('texte313')" class="objet_dev break-words text-black text-xl ml-10"></p><br>
             <p v-html="$t('texte315')" class="objet_dev break-words text-black text-xl ml-10"></p><br>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../icons/ic_arrow'
export default {
  name: 'index.vue',
  data () {
    return {
      icons: { arrow }
    }
  },
  methods: {
    goToIndex () {
      this.$router.push({ path: '/' })
    }
    // goToLink (page) {
    //   this.$router.push({ path: '/' + page })
    // },
    // goToContacts () {
    //   window.open('https://www.atlantiqueassurances.bj/nous-contacter/', '_blank')
    // },
    // goToWebSite () {
    //   window.open('https://www.atlantiqueassurances.bj/', '_blank')
    // },
    // openUrl () {
    //   window.open('https://app.aab.bj/')
    // }
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900&display=swap');
  .page {
    font-family: 'Roboto', sans-serif;
    width: 100%;
  }
  .first {
    background-color: #006FB1;
    text-align: center;
    color: white;
    height: 30em;
    margin-top: -10px;

  }

  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 2em;
    margin-top: 10;
    padding-top: 1em;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 16em;
    margin-right: 16em;
    background-color: white;
    z-index: 99;
    margin-top: -10em;
    box-shadow: -2px 5px 20px 0px rgba(185, 185, 185, 0.25), 0 10px 5px 0 rgba(0, 0, 0, 0);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #006FB1;
    padding: 5em 8em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
  @media only screen and (max-width: 600px) {
    .page {
      width: 100%;
      overflow: hidden;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;

    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 0;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 1em;
      margin-right: 1em;
      background-color: white;
      z-index: 99;
      top: -6em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 1em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    .page {
      width: 100%;
      overflow: hidden;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;

    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 0;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 1em;
      margin-right: 1em;
      background-color: white;
      z-index: 99;
      top: -12em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 1em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    .page {
      width: 100%;
      overflow: hidden;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;

    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 8em;
      margin-right: 8em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 4em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    .page {
      font-family: 'Roboto', sans-serif;
      width: 100%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 16em;
      margin-right: 16em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 8em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
  @media only screen and (min-width: 1408px) {
    .page {
      font-family: 'Roboto', sans-serif;
      width: 100%;
    }
    .first {
      background-color: #006FB1;
      text-align: center;
      color: white;
      height: 30em;
      margin-top: -10px;
    }

    p.arrow {
      width: 1.5em;
    }
    .title {
      color: white;
      font-weight: 400;
      font-size: 2em;
      margin-top: 10;
      padding-top: 1em;
    }
    p.rema_title {
      font-size: 1em;
    }
    .bloc1 {
      margin-left: 16em;
      margin-right: 16em;
      background-color: white;
      z-index: 99;
      top: -7em;
      box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
    }
    .second {
      padding: 2em 1em;
    }
    .objet {
      font-size: 1.5em;
      padding-bottom: 1em;
    }
    .objet_dev {
      font-weight: 300;
    }
    h2 {
      margin-top: -1em;
      padding-bottom: 1em;
    }
    .bloc6 {
      background-color: #16B853;
      padding: 5em 8em;
      color: white;
    }
    .footer_title {
      font-weight: 600;
      font-size: 1em;
    }
    .infos {
      font-size: 0.9em;
      font-weight: 300;
    }
    .infos a {
      color: white;
    }
  }
</style>
